import { useMsal } from '@azure/msal-react'
import UserIcon from '@mui/icons-material/AccountCircle'
import React from 'react'
import useCustomerConfig from '../hooks/useCustomerConfig'

const UserIconAndName = () => {
  const { instance, accounts } = useMsal()
  const handleLogin = (loginType: any) => {
    if (loginType === 'popup') {
      instance.loginPopup().catch((e) => {
        console.error(e)
      })
    } else {
      instance.loginRedirect().catch((e) => {
        console.error(e)
      })
    }
  }

  const handleLogout = () => {
    const answer = window.confirm('Are you sure you want to sign out?')
    if (!answer) return
    instance.logout().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div
      className="flex items-center gap-2 flex-col p-4"
      tabIndex={0}
      role={'button'}
      onClick={handleLogin}
    >
      <UserIcon style={{ fontSize: 40 }} />
      {accounts && accounts.length ? (
        <span>{accounts[0].name}</span>
      ) : (
        <span>Logg in</span>
      )}
    </div>
  )
}

export const Sidebar = ({
  extraChildren,
  isOpen,
}: {
  extraChildren?: React.ReactNode
  isOpen: boolean
}) => {
  const customerConfig = useCustomerConfig()

  return (
    <header
      className={`${customerConfig.theme.isLight ? 'bg-white text-black dark:bg-gray-900 dark:text-white' : customerConfig.theme.bgColor + ' text-white'} h-full flex flex-col items-center justify-between ${isOpen ? 'w-60 p-4' : 'w-0 p-0 overflow-hidden'} lg:w-60 lg:p-4 transition-all duration-500 ease-in-out`}
    >
      <div
        className={`flex flex-col items-center rounded-xl text-center gap-4 mt-16`}
      >
        <h1 className="text-2xl font-bold m-0">{customerConfig.header}</h1>
        <span>{customerConfig.subheader}</span>
      </div>
      <div>
        <UserIconAndName />
        {extraChildren}
      </div>
    </header>
  )
}
